import { Box, Button, Modal, TextField, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { showComponent } from "../../helper/helpers";
import { modalStyle, multiValueModalStyle } from "../../../constants/srfConstants";
import { notifiy } from "../../notification/Notification";
import { BASE_URL } from "../../../global";
import { useEffect, useState } from "react";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

// TODO: replace with shubhams code for multivalue selection
export const RenderMultivalueModal = (props) => {
  const {
    multiValueData,
    multiValueModalOpen,
    setMultiValueModalOpen,
    readingRows,
    updateCellValue,
    unitFilter,
    instrumentArray,
  } = props;

  const [unitsByDisciplineID, setUnitsByDisciplineID] = useState([]);

  useEffect(() => {
    const instrumentID = readingRows?.[multiValueData?.rowIndex]?.instrumentId;
    const disciplineID = instrumentArray?.find(
      (inst) => inst?.id === instrumentID
    )?.disciplineId;
    const newUnits =
      unitFilter?.filter((unit) => unit?.disciplineId === disciplineID) ||
      unitFilter;
    newUnits.unshift("");
    setUnitsByDisciplineID(newUnits);
  }, [multiValueData?.rowIndex, readingRows, instrumentArray, unitFilter]);

  if (multiValueData?.rowIndex != undefined && multiValueData?.key != undefined)
    return (
      <Modal
        open={multiValueModalOpen}
        onClose={() => {
          setMultiValueModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box sx={multiValueModalStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: "5px",
            }}
          >
            <h5>Update Multiple values</h5>
            <Button
              variant="contained"
              size="small"
              onClick={() => setMultiValueModalOpen(false)}
            >
              X
            </Button>
          </Box>
          <table className="srf-multivalue-table">
            <tr>
              {["ranges", "operatingRange"].includes(multiValueData.key) ? (
                <th>Min Value</th>
              ) : (
                ""
              )}
              {/* {["ranges","operatingRange"].includes(multiValueData.key) ? <th>{" - "}</th> : ""} */}
              <th>
                {["ranges", "operatingRange"].includes(multiValueData.key)
                  ? "Max Value"
                  : "Value"}
              </th>
              <th>Unit</th>
              <th></th>
            </tr>
            {readingRows[multiValueData.rowIndex]?.[multiValueData.key]
              ?.split("||")
              .map((e, idx) => (
                <tr>
                  {["ranges", "operatingRange"].includes(multiValueData.key) ? (
                    <td>
                      <TextField
                      inputProps={{
                        autoComplete: 'off',
                      }}
                        value={e.split("|")[0].split("#")[0]}
                        onChange={(e) => {
                          let ctr0 =
                            readingRows[multiValueData.rowIndex]?.[
                              multiValueData.key
                            ].split("||");
                          let ctr1 = ctr0[idx].split("|");
                          let ctr2 = ctr1[0].split("#");
                          ctr2[0] = e.target.value;
                          ctr1[0] = e.target.value !== "" ? ctr2.join("#") : "";
                          ctr0[idx] = ctr1.join("|");
                          updateCellValue(
                            multiValueData.rowIndex,
                            multiValueData.key,
                            ctr0.join("||")
                          );
                        }}
                      />
                    </td>
                  ) : (
                    ""
                  )}
                  {multiValueData.key === 3 || multiValueData.key === 18 ? (
                    <td>{" - "}</td>
                  ) : (
                    ""
                  )}
                  <td>
                    <TextField
                    inputProps={{
                      autoComplete: 'off',
                    }}
                      value={
                        ["ranges", "operatingRange"].includes(
                          multiValueData.key
                        )
                          ? e.split("|")[1]
                            ? e.split("|")[1].split("#")[0]
                            : ""
                          : e.split("#")[0]
                      }
                      onChange={(e) => {
                        let ctr0 =
                          readingRows[multiValueData.rowIndex]?.[
                            multiValueData.key
                          ].split("||");
                        let ctr1 = ctr0[idx].split("|");
                        if (
                          ["ranges", "operatingRange"].includes(
                            multiValueData.key
                          )
                        ) {
                          let ctr2 = ctr1[1] ? ctr1[1].split("#") : [""];
                          ctr2[0] = e.target.value;
                          ctr1[1] = ctr2.join("#");
                          ctr0[idx] = ctr1.join("|");
                        } else {
                          let ctr2 = ctr1[0].split("#");
                          ctr2[0] = e.target.value;
                          ctr1[0] = ctr2.join("#");
                          ctr0[idx] = ctr1;
                        }
                        updateCellValue(
                          multiValueData.rowIndex,
                          multiValueData.key,
                          ctr0.join("||")
                        );
                      }}
                      // disabled={
                      //   ["ranges","operatingRange"].includes(multiValueData.key)
                      //     ? e.split("|")[0].split("#")[0] === ""
                      //     : false
                      // }
                    />
                  </td>
                  <td>
                    <select
                      styles={{ width: "100%" }}
                      onChange={(e) => {
                        let ctr0 =
                          readingRows[multiValueData.rowIndex]?.[
                            multiValueData.key
                          ].split("||");
                        let ctr1 = ctr0[idx].split("|");
                        let ctr2 = "";
                        if (ctr1[0].length >= 0) {
                          ctr2 = ctr1[0].split("#");
                          ctr2 = [ctr2[0], e.target.value];
                          ctr1[0] = ctr2.join("#");
                        }
                        if (ctr1.length > 1) {
                          ctr2 = ctr1[1].split("#");
                          ctr2 = [ctr2[0], e.target.value];
                          ctr1[1] = ctr2.join("#");
                        }
                        ctr0[idx] = ctr1.join("|");
                        updateCellValue(
                          multiValueData.rowIndex,
                          multiValueData.key,
                          ctr0.join("||")
                        );
                      }}
                      value={
                        e.split("|")[0].split("#")[1]
                          ? e.split("|")[0].split("#")[1].split("(")[0].trim()
                          : ""
                      }
                      // disabled={
                      //   ["ranges","operatingRange"].includes(multiValueData.key)
                      //     ? e.split("|")[0].split("#")[0] === "" ||
                      //       !e.split("|")[1]
                      //     : e === ""
                      // }
                    >
                      {unitsByDisciplineID?.map((option) => (
                        <option value={option.symbol}>{option.symbol}</option>
                      ))}
                    </select>
                  </td>
                  <td>
                    {readingRows[multiValueData.rowIndex]?.[
                      multiValueData.key
                    ].split("||").length > 1
                      ? showComponent("delete_in") && (
                          <DeleteIcon
                            style={{ color: "#dc3545" }}
                            onClick={(e) => {
                              let tmp = readingRows[multiValueData.rowIndex]?.[
                                multiValueData.key
                              ]
                                .split("||")
                                .filter((_, i) => i !== idx);
                              updateCellValue(
                                multiValueData.rowIndex,
                                multiValueData.key,
                                tmp.join("||")
                              );
                            }}
                          />
                        )
                      : ""}
                  </td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={
                  ["ranges", "operatingRange"].includes(multiValueData.key)
                    ? 4
                    : 2
                }
              >
                <Button
                  onClick={(e) => {
                    let ctr =
                      readingRows[multiValueData.rowIndex]?.[multiValueData.key];
                    ctr += "||";
                    updateCellValue(
                      multiValueData.rowIndex,
                      multiValueData.key,
                      ctr
                    );
                  }}
                >
                  <b style={{ fontSize: "18px" }}>ADD</b>
                  <AddIcon />
                </Button>
              </td>
            </tr>
          </table>
        </Box>
      </Modal>
    );
  else return <></>;
};

export const RenderModal = (props) => {
  const {
    modalOpen,
    handleClose,
    readingRows,
    currentRowIndex,
    setReadingRows,
    tempReading,
    DUC_ID,
    srfDetails,
  } = props;

  const user = [localStorage.getItem("id"), localStorage.getItem("userName")];

  const tempReadingResult = (decision) => {
    if (decision) {
    } else {
      let newRows = [...readingRows];
      newRows[currentRowIndex] = {
        ...newRows[currentRowIndex],
        instrumentId: "",
        DUCID: "",
        serialNumber: "",
        ranges: "",
        lc: "",
        make: "",
        model: "",
        ConOfDuc: "",
        calFrequency: "",
        accuracy: "",
        calibrationType: "",
        location: "",
        locationOfInstrument: "",
        calPoint: "",
        calMethod: "",
        labCapabilities: "",
        department: "",
        operatingRange: "",
      };
      Object.keys(newRows[currentRowIndex].extraColumns).forEach((key) => {
        newRows[currentRowIndex].extraColumns[key] = "";
      });
      setReadingRows([...newRows]);
    }
    handleClose();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      size="md"
    >
      <Box sx={modalStyle}>
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "24px",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "24px",
              flexDirection: "column",
            }}
          >
            <div style={{ flex: 1, width: "100%" }}>
              <span>
                DUC is within the due date. Do you want to ignore or clear
                selected row?
              </span>
            </div>
            <div style={{ flexBasis: "50px" }}></div>
            <div style={{ flex: 1, width: "100%", display: "flex" }}>
              <div style={{ flex: 1, width: "100%" }}></div>
              <div style={{ flex: 1, width: "100%" }}>
                <Tooltip
                  title="Ignore Warning and adds Reading"
                  placement="top-start"
                >
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ m: 0, ml: 2 }}
                    onClick={(e) => {
                      tempReadingResult(true);
                      axiosWithToken
                        .get(
                          BASE_URL +
                            `users?_where=(status,eq,1)~and((type,eq,1)~or((type,eq,4)${
                              srfDetails?.branch
                                ? `~and(branch,eq,${srfDetails?.branch})`
                                : ""
                            }))&fields=id`
                        )
                        .then((res) => {
                          res.data.map((e) =>
                            notifiy({
                              _from: user[0],
                              _to: e.id,
                              body: `${DUC_ID[1]}(${DUC_ID[0]}) is within Due Date, but user ${user[1]} (${user[0]}) is trying to create new SRF for Instrument!`,
                            })
                          );
                        });
                    }}
                  >
                    Ignore
                  </Button>
                </Tooltip>
              </div>
              <div style={{ flex: 1, width: "100%" }}>
                <Tooltip
                  title="Clear readings but keeps instrument"
                  placement="top-start"
                >
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ m: 0, ml: 2 }}
                    onClick={(e) => tempReadingResult()}
                  >
                    Clear
                  </Button>
                </Tooltip>
              </div>
              <div style={{ flex: 1, width: "100%" }}></div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
