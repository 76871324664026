import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../global";
import PrintIcon from "@mui/icons-material/Print";

import Tooltip from "@mui/material/Tooltip";
import { Autocomplete, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { showComponent } from "../helper/helpers";
import "../helper/helper.css";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

const _clientId = localStorage.getItem("clientId");
const userType = localStorage.getItem("type");

const columns = [
  { id: "id", label: "Client Complaint Number", minWidth: 20 },
  {
    id: "companyName",
    label: "Client Name",
    minWidth: 40,
    align: "left",
  },
  {
    id: "address",
    label: "Address",
    minWidth: 40,
    align: "left",
  },
  { id: "date", label: "Date of Complaint Received", minWidth: 80 },
  {
    id: "closureDate",
    label: "Date of Closure of Complaint",
    align: "left",
    minWidth: 80,
  },
  {
    id: "createdBy",
    label: "Created By",
    align: "left",
    minWidth: 40,
  },
  {
    id: "createdBy",
    label: "Approved By",
    align: "left",
    minWidth: 40,
  },
];

function createData(
  id,
  date,
  customerId,
  companyName,
  address,
  description,
  acknowledgedBy,
  rootCauseAnalysis,
  correction,
  correctiveAction,
  target,
  responsibility,
  closureDate,
  status,
  remark,
  lastModified,
  createdBy,
  approvedby
) {
  return {
    id,
    date,
    customerId,
    companyName,
    address,
    description,
    acknowledgedBy,
    rootCauseAnalysis,
    correction,
    correctiveAction,
    target,
    responsibility,
    closureDate,
    status,
    remark,
    lastModified,
    createdBy,
    approvedby,
  };
}

const CustomerComplaintList = (props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [complaintList, setComplaintList] = React.useState([]);
  const [complaintData, setComplaintData] = React.useState([]);
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState("Client");
  const [searchKey, setSearchKey] = React.useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getInvoiceList = (event) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        Client: "c.companyName",
      }[searchBy];
      whereConditions = `WHERE ${colName} LIKE '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " WHERE ";
      } else {
        whereConditions += " AND ";
      }

      whereConditions += ` date BETWEEN '${moment(from).format(
        "YYYY-MM-DD"
      )}' AND '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (userType == "3") {
      whereConditions +=
        whereConditions.length > 0
          ? ` and (cc.customerId=${_clientId})`
          : `where (cc.customerId=${_clientId})`;
    }
    // if (whereConditions == "") {
    //   whereConditions += ` where cert.status != -1`;
    // } else {
    //   whereConditions += ` and cert.status != -1 `;
    // }
    if (rowsPerPage !== -1) {
      pagination_settings = `LIMIT ${rowsPerPage} OFFSET ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT cc.*, c.companyName,c.address FROM customerComplaint cc INNER JOIN clients c ON cc.customerId = c.id ${whereConditions} ORDER BY id DESC ${pagination_settings}`,
    };

    axiosWithToken
      .post(BASE_URL + "dynamic", data)
      .then((res) => {
        setComplaintList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitSiteDelete = (rowIndex, id) => {
    props.setLoader(true);
    let newInvoiceData = [...complaintData];

    if (newInvoiceData[rowIndex] != undefined) {
      axiosWithToken
        .delete(BASE_URL + `customerComplaint/${id}`)
        .then((res) => {
          toast.success("deleted successfully");
          props.setLoader(false);
          newInvoiceData.splice(rowIndex, 1);
          setComplaintData([...newInvoiceData]);
        })
        .catch((err) => {
          if (err.message !== "request_aborted") {
            toast.error("err : ", err);
          }
          // props.setLoader(false);
        });
    }
  };

  // api calls
  function getUsers() {
    const data = {
      query: "SELECT * FROM users",
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  const initiatInvoiceList = () => {
    var rowData = [];

    for (let i = 0; i < complaintList.length; i++) {
      rowData.push(
        createData(
          complaintList[i].id,
          complaintList[i].date
            ? moment(complaintList[i].date).format("DD-MM-YYYY")
            : "",
          complaintList[i].customerId,
          complaintList[i].companyName,
          complaintList[i].address,
          complaintList[i].description,
          complaintList[i].acknowledgedBy,
          complaintList[i].rootCauseAnalysis,
          complaintList[i].correction,
          complaintList[i].correctiveAction,
          complaintList[i].target,
          complaintList[i].responsibility,
          complaintList[i].closureDate
            ? moment(complaintList[i].closureDate).format("DD-MM-YYYY")
            : "",
          complaintList[i].status,
          complaintList[i].remark,
          complaintList[i].createdBy,
          complaintList[i].approvedby
        )
      );
    }
    setComplaintData(rowData);
  };

  const search = () => {
    getInvoiceList();
  };

  useEffect(() => {
    initiatInvoiceList();
  }, [complaintList]);

  useEffect(() => {
    getInvoiceList();
    getUsers();
  }, []);
  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Toolbar>
          {/* {showComponent("add") && ( */}
          <Button
            variant="contained"
            size="small"
            component={Link}
            to="/editComplaint"
          >
            create Client COMPLAIN
          </Button>
          {/* )} */}
        </Toolbar>
        <hr />

        <div
          className="mb-2"
          style={{
            display: "flex",
            float: "right",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ marginLeft: "10px", marginRight: "10px" }}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              classNames="mr-2"
            >
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="From"
                value={from ? new Date(from) : ""}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => setFrom(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </div>

          <div style={{ marginRight: "10px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="To"
                value={to ? new Date(to) : ""}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => setTo(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
                style={{ marginRight: "20px" }}
              />
            </LocalizationProvider>
          </div>

          <Autocomplete
            sx={{
              m: 0,
              minWidth: 180,
              marginLeft: "10px",
            }}
            size="small"
            value={searchBy}
            id="combo-box-demo"
            options={[{ key: "companyName", label: "Client" }]}
            renderInput={(params) => (
              <TextField {...params} label="Search By" />
            )}
            onInputChange={(event, newInputValue) => {
              setSearchBy(newInputValue);
            }}
          />
          <TextField
            id="outlined-basic"
            label={"enter " + searchBy}
            inputProps={{
              autoComplete: 'off',
            }}
            size="small"
            variant="outlined"
            onChange={(e) => setSearchKey(e.target.value)}
            sx={{ marginLeft: "10px" }}
          />
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            style={{ marginLeft: "10px" }}
            onClick={() => {
              search();
            }}
          >
            <SearchIcon />
          </Button>
        </div>
        <br />

        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {complaintData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      // let value = row[column.id];
                      let value;
                      if (column.id === "createdBy") {
                        let userObject = users.filter(
                          (e) => e.id === row[column.id]
                        );
                        value = row[column.id] ? userObject[0]?.userName : null;
                      } else {
                        value = row[column.id];
                      }

                      if (column.id == "id") {
                        value = page * rowsPerPage + index + 1;
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}

                    <TableCell>
                      <ButtonGroup
                        size="small"
                        aria-label="small button group"
                        className="action-container"
                      >
                        {showComponent("show") && (
                          <Tooltip title="Edit Complaint" placement="top-start">
                            <Button
                              style={{
                                borderColor: "#dc3545",
                              }}
                              component={Link}
                              to={`/editComplaint/${row.customerId}/${row.id}`}
                            >
                              <PreviewIcon />
                            </Button>
                          </Tooltip>
                        )}

                        {showComponent("show") && (
                          <Tooltip
                            title="Print Complaint"
                            placement="top-start"
                          >
                            <Button
                              style={{
                                borderColor: "#dc3545",
                              }}
                              component={Link}
                              to={`/complaintPrint/${row.id}`}
                            >
                              <PrintIcon />
                            </Button>
                          </Tooltip>
                        )}
                        {showComponent("delete") && (
                          <Tooltip
                            title="Delete Complaint"
                            placement="top-start"
                          >
                            <Button
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Really want to Delete Complaint?"
                                  )
                                ) {
                                  submitSiteDelete(
                                    page * rowsPerPage + index,
                                    row.id
                                  );
                                }
                              }}
                              style={{
                                borderColor: "#dc3545",
                              }}
                            >
                              <DeleteIcon style={{ color: "#dc3545" }} />
                            </Button>
                          </Tooltip>
                        )}
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={complaintData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ToastContainer />
      </TableContainer>
    </div>
  );
};

export default CustomerComplaintList;
