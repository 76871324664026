import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link, useLocation } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { TextField, Autocomplete, useMediaQuery, Modal, Grid } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import IconButton from "@mui/material/IconButton";
import {
  deleteEnquiryThunk,
  getEnquiesTableThunk,
  getEnquiryListData,
} from "./enquirySlice";
import { useSelect } from "@mui/base";
import { useDispatch } from "react-redux";
import ExcelDownload from "../../../utils/components/excelDownload";
import { enquiryListGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");



const columns = [
  { id: "id", label: "Sr. No", minWidth: 40 },

  {
    id: "companyName",
    label: "Client",
    align: "left",
    minWidth: 40,
  },
  {
    id: "address",
    label: "Address",
    align: "left",
    minWidth: 40,
  },
  {
    id: "emailId",
    label: "emailId",
    align: "left",
    minWidth: 40,
  },
  {
    id: "source",
    label: "Source",
    align: "left",
    minWidth: 40,
  },
  {
    id: "createdby",
    label: "createdby",
    align: "left",
    minWidth: 40,
  },
  {
    id: "date",
    label: "Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "followUpDate",
    label: "Followup Date",
    align: "left",
    minWidth: 40,
  },
  // {
  //   id: "status",
  //   label: "Status",
  //   align: "left",
  //   minWidth: 40,
  // },
];

const sourceArr = {
  0: "Visit",
  1: "Tele",
  2: "Justdial",
  3: "Customer Call",
};

const leadStatusArr = {
  0: "Pending",
  1: "Won",
  2: "Lost",
};

function createData(
  id,
  companyName,
  address,
  emailId,
  source,
  createdby,
  leadStatus,
  rating,
  date,
  followUpDate,
  status,
  clientId
) {
  return {
    id,
    companyName,
    address,
    emailId,
    source,
    createdby,
    leadStatus,
    rating,
    date,
    followUpDate,
    status,
    clientId,
  };
}
const notNeededColumn = ["id", "clientId", "createdBy", "status"];
export default function EnquiryList() {
  const enquiryListGuides = enquiryListGuide();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const urlPath = useLocation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [enquiryList, setEnquiryList] = React.useState([]);
  const [enquiryData, setEnquiryData] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "Client");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const [quotationData, setQuotationData] = React.useState([]);
  const [searched, setSearched] = React.useState(false);
  const dispatch = useDispatch();
  const data = useSelect(getEnquiryListData);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    // dispatch(deleteEnquiryThunk({ id }));
    axiosWithToken
      .patch(url + `enquiry/${id}`, jsonData)
      .then((res) => {
        toast.success("Enquiry was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getQuotationData = () => {
    let url = BASE_URL;
    const payload = {
      query: "SELECT enquiryId FROM quotation WHERE enquiryId IS NOT NULL;",
    };
    axiosWithToken
      .post(url + `dynamic`, payload)
      .then((res) => {
        setQuotationData(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  const getQuery = (count) => {
    let whereConditions = [];
    let pagination_settings = "";
    let whereConditions2 = "";
    let pagination_settings2 = "";
  
    if (searchBy !== "" && searchKey != "") {
      let colName = {
        Client: "companyName",
      }[searchBy];
      whereConditions.push(`${colName} like '%${searchKey}%'`);
      whereConditions2 = `,(${colName},like,${searchKey})`;
    }
  
    if (to != null && from != null) {
      whereConditions.push(
        `qt.date between '${moment(from).format("YYYY-MM-DD")}' and '${moment(
          to
        ).format("YYYY-MM-DD")}' `
      );
      whereConditions2 += `,(qt.date,bw,${moment(from).format(
        "YYYY-MM-DD"
      )},${moment(to).format("YYYY-MM-DD")})`;
    }
  
    whereConditions.push("en.status=1");
  
    whereConditions =
      whereConditions.length > 0
        ? `where ${whereConditions.join(" and ")}`
        : "";
  
    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
      pagination_settings2 += `&_p=${page}&_size=${rowsPerPage}`;
    }
  
    let data = {
      query: `SELECT client.companyName, en.clientId, en.contactPersonName, en.emailId, en.status, en.followUpDate, en.date, en.source, en.rating, en.id, en.leadStatus, a.address AS address, client.contact, u.username AS createdby FROM enquiry AS en left JOIN clients AS client ON en.clientId = client.id left JOIN users AS u ON en.createdby = u.id LEFT JOIN addresses AS a ON en.address = a.id ${whereConditions} order by en.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `SELECT client.companyName, en.clientId, en.contactPersonName, en.emailId, en.status, en.followUpDate, en.date, en.source, en.rating, en.id, en.leadStatus, a.address AS address, client.contact, u.username AS createdby FROM enquiry AS en left JOIN clients AS client ON en.clientId = client.id left JOIN users AS u ON en.createdby = u.id LEFT JOIN addresses AS a ON en.address = a.id ${whereConditions} order by en.id DESC`;

    return data;
  };  

  function getClientList() {
    let data = getQuery();
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        let clientsId = {};
        res.data.map((dt) => (clientsId[dt.createdby] = true));
        clientsId = Object.keys(clientsId).join(",");
        setEnquiryList(
          res.data.map((e) => {
            return {
              ...e,
              source: sourceArr[parseInt(e.source)],
              leadStatus: leadStatusArr[parseInt(e.leadStatus)],
            };
          })
        );
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  // api calls
  function getTotalRows() {
    let data = getQuery(true);
    axiosWithToken
    .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data?.length);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);
    setSearched(true);
    getClientList();
    getTotalRows();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched", searched);
    sessionStorage.removeItem("currentPath");
    setSearchBy("Client");
    setSearchKey("");
    setSearched(false); 
  };

  useEffect(() => {
    getQuotationData();
  }, []);
  
  useEffect(() => {
      getClientList();
      getTotalRows();
  }, [rowsPerPage, page]);

  useEffect(() => {
    if(searched==false){
      getClientList();
      getTotalRows();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);

  const enquiryDatas = () => {
    var rowDaata = [];
    for (let i = 0; i < enquiryList.length; i++) {
      rowDaata.push(
        createData(
          enquiryList[i].id,
          enquiryList[i].companyName,
          enquiryList[i].address,
          enquiryList[i].emailId,
          enquiryList[i].source,
          enquiryList[i].createdby,
          enquiryList[i].leadStatus,
          enquiryList[i].rating,

          enquiryList[i].date
            ? moment(enquiryList[i].date).format("YYYY-MM-DD")
            : "",
          enquiryList[i].followUpDate
            ? moment(enquiryList[i].followUpDate).format("YYYY-MM-DD")
            : "",
          enquiryList[i].status,
          enquiryList[i].clientId
        )
      );
    }
    setEnquiryData(rowDaata);
  };

  const filterSection = () => (
    <Grid
      container
      spacing={2}
      // justifyContent="flex-end"
      alignItems="center"
      style={{ padding: "5px 5px", marginBottom: "2px" }}
    >
      <Grid item xs={6} sm={6} md={2} lg={2} id="enquirylist_datefrom">
      <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="From"
              value={from ? new Date(from) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setFrom(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
      </Grid>
      <Grid item xs={6} sm={6} md={2} lg={2} id="enquirylist_dateto">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="To"
              value={to ? new Date(to) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setTo(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
              style={{ marginRight: "20px" }}
            />
          </LocalizationProvider>
      </Grid>
      <Grid item xs={6} sm={6} md={2} lg={2}>
      <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
            marginLeft: "10px",
          }}
          size="small"
          value={searchBy}
          id="enquirylist_searchby"
          options={[{ key: "companyName", label: "Client" }]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
            setSearched(false);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2}>
      <TextField
          id="enquirylist_searchbytext"
          label={"enter " + searchBy}
          size="small"
          inputProps={{
            autoComplete: 'off',
          }}
          variant="outlined"
          value={searchKey}
          onChange={(e) => {
              setSearchKey(e.target.value)
              setSearched(false);
            }}
          sx={{ marginLeft: "10px" }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={1} lg={1} textAlign={"left"}>
      <Button
          id="enquirylist_searchbtn"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            searched ? clearSearch() : search();
          }}
        >
          {searched ? <RestartAltIcon /> : <SearchIcon />}	
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2} textAlign={"left"}>
      <ExcelDownload
          finalData={enquiryData}
          notNeededColumn={notNeededColumn}
        />
      </Grid>
    </Grid>
  );

  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  useEffect(() => {
    enquiryDatas();
  }, [enquiryList]);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      {filterModal()}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar>
          <Button
            id="enquirylist_createbtn"
            variant="contained"
            size="small"
            component={Link}
            to="/master/enquiry/createEnquiry"
            onClick={() => {}}
          >
            <b>create enquiry</b>
          </Button>
        </Toolbar>
        <Box sx={{ display: "flex", gap: "0rem" }}>
              <Button
                style={{ display: isMobile ? "block" : "none" }}
                onClick={handleFilterOpen}
              >
                <FilterAltIcon />
              </Button>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              enquiryListGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
        </Box>
      </div>
      <br />
      {!isMobile && filterSection()}
      <table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="enquirylist_table"
        width="100%"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {enquiryData.map((row, index) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                {columns.map((column) => {
                  let value = row[column.id];
                  if (column.id == "id") {
                    value = index + 1;
                  }
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.format && typeof value === "number"
                        ? column.format(value)
                        : value}
                    </TableCell>
                  );
                })}
                <TableCell>
                  <ButtonGroup size="small" aria-label="small button group">
                    <Tooltip title="Edit Enquiry" placement="top-start">
                      <Button
                        id="enquirylist_edit"
                        component={Link}
                        to={`/master/enquiry/EditEnquiry/${row.id}`}
                      >
                        <PreviewIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Enquiry FollowUp" placement="top-start">
                      <Button
                        id="enquirylist_followup"
                        component={Link}
                        to={`/master/enquiry/enquiryFollowUp/${row.id}`}
                      >
                        <BookmarkIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip
                      title={
                        quotationData.some((data) => data.enquiryId === row.id)
                          ? "Already Quotation is created"
                          : "Prepare quotation"
                      }
                      placement="top-start"
                    >
                      <Button
                        id="enquirylist_preparequotation"
                        component={Link}
                        to={`/quotation/createQuotation/${row.id}/${row.date}/${row.clientId}`}
                        disabled={quotationData.some(
                          (data) => data.enquiryId === row.id
                        )}
                      >
                        <ReceiptLongIcon />
                      </Button>
                    </Tooltip>

                    <Tooltip title="Delete Enquiry" placement="top-start">
                      <Button
                        id="enquirylist_delete"
                        disabled={!(editAccess?.includes(2) || editAccess?.includes(0))}
                        onClick={(e) => {
                          if (
                            window.confirm("Really want to delete Enquiry?")
                          ) {
                            submitSiteDelete(row.id);
                          }
                        }}
                      >
                        <DeleteIcon style={{ color:!(editAccess?.includes(2) || editAccess?.includes(0)) ? 'lightgray' : '#dc3545' }} />
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component="div"
        colSpan={3}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        ActionsComponent={TablePaginationActions}
      />
      <ToastContainer />
    </TableContainer>
  );
}
