import React from "react";
import { Box, Tooltip } from "@mui/material";


import {
  moduleList,
  rolesArray,
  userDesignation,
  userTypes,
  editAccessOptions,
} from "../../constants/masterUserConstants";

const settingKeyInfo = [
  {
    key: "CertificateNumberByDatasheet",
    reason: "Certificate No change by date of callibration",
  },
  {
    key: "CSRFS",
    reason: "show CSRF Form Button in srf",
  },
  {
    key: "Customer Annexure",
    reason: "show Customer Annexure Button in srf",
  },
  {
    key: "ClientsUser (global.js)",
    reason: "remove Client option role in usermaster",
  },
  {
    key: "SOPUpload",
    reason: "show button in certificatelist, instrument",
  },
  {
    key:"TrainingAndMasterCertificate",
    reason: "show button in usermaster , certificatelist, masterEQP",
  },
  {
    key:"TablePartition",
    reason: "show in instrument Checkbox",
  },
  {
    key:"CompanyList",
    reason: "add compnay list",
  },
  {
    key:"CSRFS",
    reason: "show button in srf",
  },
  {
    key:"OfflineMode (global.js)",
    reason: "remove offline button in navbar",
  }
];

const DesignationTable = ({ tableName, tableimport }) => {
  if (!Array.isArray(tableimport)) {
    console.error(
      `Expected an array for tableimport, got: ${typeof tableimport}`
    );
    return null; 
  }
  

  return (
    <div>
      <h5 style={{ textAlign: "left" }}>
        <b>{tableName}</b>
      </h5>
      <br />
      <table>
        <thead>
          <tr>
            <th style={{ padding: "3px" }}>ID</th>
            <th style={{ padding: "3px" }}>Name</th>
          </tr>
        </thead>
        <tbody>
          {tableimport.map((item) => (
            <tr key={item.id}>
              <td style={{ padding: "3px" }}>{item.id}</td>
              <td style={{ padding: "3px", textAlign: "left" }}>
                {item?.name}
                {item?.label}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const AppInfo = () => {
  return (
    <Tooltip style={{ padding: "1rem" }}>
      <Box
        sx={{
          margin: "1rem",
          padding: "1.5rem 1rem",
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "0.5rem",
        }}
      >
        <DesignationTable tableName={"User Types"} tableimport={userTypes} />
        <DesignationTable tableName={"Roles"} tableimport={rolesArray} />
        <DesignationTable
          tableName={"User Designation"}
          tableimport={userDesignation}
        />
        <DesignationTable tableName={"Modules"} tableimport={moduleList} />
        <DesignationTable
          tableName={"Access Options"}
          tableimport={editAccessOptions}
        />
      </Box>
      <Box sx={{ margin: "1rem", padding: "0.5rem 1rem", }}>
        <table style={{width:"100%"}}>
          <thead>
            <tr>
              <th style={{ padding: "3px" }}>key</th>
              <th style={{ padding: "3px" }}>Reason</th>
            </tr>
          </thead>
          <tbody>
            {settingKeyInfo.map((item) => (
              <tr key={item.id}>
                <td style={{ padding: "3px", textAlign: "left" }}>
                  {item.key}
                </td>
                <td style={{ padding: "3px", textAlign: "left" }}>
                  {item?.reason}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      <br/>
    </Tooltip>
  );
};

export default AppInfo;
